@tailwind base;
@tailwind components;
@tailwind utilities;

body{
	font-family: Arial, Helvetica, sans-serif; 
	background: #090D1F;
	overflow-x: hidden;
}

.post-content ol {
    margin: 1rem 0; 
    padding-left: 2rem; 
}

.post-content ul {
    margin-bottom: 1rem; 
    padding-left: 2rem; 
}

.post-content li {
    margin-bottom: 0.5rem;
	list-style-type: decimal
}
